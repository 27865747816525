@import 'bootstrap.min.css';

#main-container {
    padding-top: 70px;
}

code {
    // "Stolen" from the tomorrow theme
    .k {
        color: #8959a8;
    }

    .nf,
    .n {
        color: #3e999f;
    }

    .nb {
        color: #eab700;
    }

    .s1,
    .s2 {
        color: #718c00;
    }

    .c1,
    .c2 {
        color: #8e908c;
    }
}
